<template>
    <div v-if="loadingState === 'loading'" class="MBcont">
        <p>Загрузка ...</p>
    </div>
    <div v-else class="container itempay">
        <div class="arsenpay">
            <iframe width=100% height="500px" :src="widgetSrc" ></iframe>
            <p>Оплачивая, вы соглашаетесь с <router-link to="/oferta">договором-оферты.</router-link></p>
        </div>
     </div>
</template>

<style>
    .itempay {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .itempay h3 {
        font: 24pt/1 'Montserrat', sans-serif;
        text-transform: initial;
        text-align: left;
        margin-bottom: 20px;
    }

    .itempay-cont {
        width: 40%;        
    }
    
    .itempay-cont2 {
        display: flex;
        justify-content: space-between;      
        align-items: center;
    }
    .itempay-cont p {
        line-height: 16pt;
    }

    .itempay-img {
        width: 35%;
        transform: scaleX(-1);
        margin-right: 15px;
    }

    .arsenpay {
        display: inline-block;
        height: auto;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
        padding-bottom: 20px;
    }

    .arsenpay p{
        font-size: 11pt;
    }
</style>


<script>
    import { useRoute, useRouter } from 'vue-router'
    import { useStore } from 'vuex'
    import { ref } from 'vue'
    import { loadArsenalPaySign } from "@/service/api"
    import { getUserId } from "../store/user"

    export default {
        setup() {
            const router = useRouter()
            const route = useRoute()
            const store = useStore()
            const userId = getUserId()
            const loadingState = ref("loading");
            const widgetSrc = ref('');


            var item = store.state.goods.filter(item => item.id == route.params.id)
            if (item.length === 1) {
                item = item[0]
            } else {
                console.log('fdsgh')
                router.push('/404')
            }


            const loadArsenalPay = () => {
                return loadArsenalPaySign(item.id)
                    .then(response => {
                        loadingState.value = 'success'

                        let src = '/lib/arsenalpay/widget.html?'+
                                'user_id='+userId+'&'+
                                'good_id='+item.id+'&'+
                                'amount='+item.price+'&'+
                                'nonce='+response.data.data.nonce+'&'+
                                'sign='+response.data.data.sign+'&';
                        widgetSrc.value = src;
                    })
                    .catch(error => {
                        //todo add error view
                        console.error(error.message)
                        if (error.response.status === 404) {
                            router.push("/404")
                        }
                    })
            }

            return { loadArsenalPay, widgetSrc }
        },
        created() {
            this.loadArsenalPay();
        },
    }
</script>

<style>
    
</style>